<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <!--Search fields-->
      <school-filters
          :governorates="governorates"
          :directorates="directorates"
          :agencies="agencies"
          :selected-school-year.sync="selectedSchoolYear"
          :schoolYears="schoolYears"
          :filter-obj.sync="filterObj"
          @refreshTable="refreshTable"
          :finance-filters-student="financeFiltersStudent"
          :usage-filter="usageCounts"
          :finance-filters-teacher="financeFiltersTeacher"
          :finance-filters-driver="financeFiltersDriver"
      ></school-filters>
      <!--Buttons-->
      <v-row>
        <v-col cols="auto">
          <v-btn
              :loading="loading"
              color="teal"
              dark
              class="me-2"
              @click="refreshTable"
          >
            <v-icon left> mdi-magnify</v-icon>
            {{ $t("search") }}
          </v-btn>
          <v-btn
              :loading="loading"
              color="primary darken-2"
              dark
              @click="exportExcel"
          >
            <v-icon left>mdi-microsoft-excel</v-icon>
            {{ $t("export") }}
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn v-if="!filterObj.deleted && $store.getters.isInRole(101)"
                 :loading="loading"
                 color="error"
                 dark
                 class="me-2"
                 outlined
                 @click="toggleDeleted()"
          >
            <v-icon left> mdi-checkbox-blank-outline</v-icon>
            {{ $t("view_deleted") }}
          </v-btn>
          <v-btn v-if="filterObj.deleted && $store.getters.isInRole(101)"
                 :loading="loading"
                 color="error"
                 dark
                 class="me-2"
                 outlined
                 @click="toggleDeleted()"
          >
            <v-icon left> mdi-checkbox-outline</v-icon>
            {{ $t("view_deleted") }}
          </v-btn>

          <v-btn
              v-if="$store.getters.isInRole(8)"
              color="purple"
              class="me-2"
              :loading="loading"
              dark
              @click="dialogChangeAgency = true"
          >
            <v-icon left> mdi-key</v-icon>
            تغيير الوكالة
          </v-btn>
          <v-btn
              v-if="$store.getters.isInRole(8)"
              color="green"
              class="me-2"
              :loading="loading"
              dark
              @click="dialogActivate = true"
          >
            <v-icon left> mdi-key</v-icon>
            {{ $t("activate") }}

          </v-btn>
          <v-btn
              v-if="$store.getters.isInRole(8)"
              color="red darken-2"
              class="me-2"
              :loading="loading"
              dark

              @click="dialogDeactivate = true"
          >
            <v-icon left> mdi-block-helper</v-icon>
            {{ $t("deactivate") }}

          </v-btn>
          <v-btn
              v-if="$store.getters.isInRole(103)"
              class="me-2"
              color="primary darken-4"
              :loading="loading"
              dark

              @click="print"
          >
            <v-icon left> mdi-printer</v-icon>
            {{ $t("print") }}

          </v-btn>
          <v-btn
              v-if="$store.getters.isInRole(6)"
              color="primary"
              :loading="loading"
              dark

              @click="dialog = true"
          >
            <v-icon left> mdi-plus</v-icon>
            {{ $t("add") }}

          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="my-4"></v-divider>
      <!--DataTable-->
      <v-data-table
          :item-class="rowColor"
          :headers="headers"
          :items="schools"
          :items-per-page="-1"
          v-model="selectedSchools"
          :loading="loading"
          :loading-text="$t('loading')"
          show-select
          hide-default-footer
      >
        <template v-slot:[`item.name`]="{ item }">
          <span @click="viewItem(item)" style="cursor: pointer">{{ item.name }}</span>
          <br>
          <v-chip class="me-1" v-if="item.types.length" x-small>{{
              $t("schoolTypes." + item.types[0].school_type)
            }}
          </v-chip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip x-small color="error" class="me-1" v-if="item.types.length > 1" v-bind="attrs" v-on="on">+
                {{ item.types.length - 1 }}
              </v-chip>
            </template>
            <span class="me-1" v-for="type in item.types.slice(1)"
                  :key="item.id + '-' + type.id">{{ $t("schoolTypes." + type.school_type) }}</span>
          </v-tooltip>
          <v-chip class="me-1" x-small color="info">{{ ownerList[item.owner] }}</v-chip>
          <v-chip x-small color="success" class="me-1" v-if="item.free">مجانية</v-chip>
          <v-chip x-small color="orange darken-2" dark v-if="item.test">تجريبية</v-chip>
        </template>
        <!--        <template v-slot:item.print_status="{ item }">-->
        <!--          <v-icon :color="item.print_status ? 'success' : 'error'">-->
        <!--            mdi-printer-->
        <!--          </v-icon>-->
        <!--        </template>-->
        <template v-slot:[`item.domain`]="{ item }">
          <a target="_blank" :href="'http://' + item.domain + '.school.iq'">
            {{ item.domain }}
          </a>
          <caption>{{ item.scid }}</caption>
        </template>
        <template v-slot:[`item.directorate`]="{ item }">
          {{ item.directorate.name.replace("مديرية تربية", "") }}
          <br>
          {{ item.district }} {{ item.locality ? '-' : '' }} {{ item.locality }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | format_date_time }}
          <br>
          <span v-if="item.min_created_student >'0'">{{ item.min_created_student   | format_date_time }}</span>
          <span v-else>--</span>
        </template>
        <template v-slot:[`item.financial_accounted`]="{ item }">
          <v-row>
            <v-col cols="8">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="item.activation_status == null"
                         @click="showFinance(item)" color="warning" small label v-bind="attrs"
                         v-on="on">
                    {{ $t('schools.not_active_school') }}
                  </v-btn>
                  <v-btn
                      v-else-if="item.paid_students_count >= item.current_student_count && item.paid_students_count > 0"
                      @click="showFinance(item)" color="success" small label v-bind="attrs"
                      v-on="on">
                    {{ $t('schools.financial_accounted_school') }}
                  </v-btn>
                  <v-btn v-else color="error" @click="showFinance(item)" small label v-bind="attrs" v-on="on">
                    {{ $t('schools.not_financial_accounted_school') }}
                  </v-btn>
                </template>
                <span>{{ $t("schools.edit_finance") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4">
              <v-tooltip v-if="item.paid_students_count <= item.current_student_count - 20" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mx-1" color="error" v-bind="attrs" v-on="on">
                    mdi-alert
                  </v-icon>
                </template>
                <span>{{ $t("schools.less_paid_student_count") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.driver_teacher_count`]="{ item }">
          <v-row>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip class="my-1 mx-1" color="purple" dark small label v-bind="attrs" v-on="on">
                  {{ item.current_teacher_count }}
                </v-chip>
              </template>
              <span>{{ $t("schools.current_teacher_count") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip class="my-1" color="info" small label v-bind="attrs" v-on="on">
                  {{ item.current_driver_count }}
                </v-chip>
              </template>
              <span>{{ $t("schools.current_driver_count") }}</span>
            </v-tooltip>
          </v-row>
        </template>
        <template v-slot:[`item.student_count`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip color="normal" class="my-1" small label v-bind="attrs" v-on="on">
                {{ item.student_expected_count }}
              </v-chip>
            </template>
            <span>{{ $t("schools.student_expected_count") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="my-1 mx-1" color="info" small label v-bind="attrs" v-on="on">
                {{ item.current_student_count }}
              </v-chip>
            </template>
            <span>{{ $t("schools.current_student_count") }} </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="my-1 mx-1" color="#FF5252" text-color="white" small label v-bind="attrs" v-on="on">
                {{ item.notifications_count }}
              </v-chip>
            </template>
            <span>عدد التبليغات </span>
          </v-tooltip>
          <br>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip color="purple" class="my-1 mx-1 white--text" small label v-bind="attrs" v-on="on">
                {{ item.week_active_students }}
              </v-chip>
            </template>
            <span>{{ $t("schools.week_active_students") }}  </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip color="blue-grey" class="my-1 mx-1 white--text" small label v-bind="attrs" v-on="on">
                {{ item.month_active_students }}
              </v-chip>
            </template>
            <span>الطلاب الفاعلين لهذا الشهر  </span>
          </v-tooltip>
          <v-tooltip bottom v-if="$store.getters.isInRole(102)">
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="my-1" color="warning" small label v-bind="attrs" v-on="on">
                {{ item.at_least_once_active_students }}
              </v-chip>
            </template>
            <span>عدد الطلبة المستخدمين على الاقل مرة واحدة</span>
          </v-tooltip>
          <v-tooltip bottom v-if="$store.getters.isInRole(102)">
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="mx-1 my-1" color="success" small label v-bind="attrs" v-on="on">
                {{ item.paid_students_count }}
              </v-chip>
            </template>
            <span>{{ $t("schools.paid_students_count") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <v-tooltip bottom v-if="$store.getters.isInRole(102)">
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="my-1" color="success" small label v-bind="attrs" v-on="on">
                {{ item.students_has_number_count }}
              </v-chip>
            </template>
            <span>{{ $t("schools.students_has_number_count") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="$store.getters.isInRole(102)">
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="mx-1 my-1" color="error" small label v-bind="attrs" v-on="on">
                {{ item.students_not_has_number_count }}
              </v-chip>
            </template>
            <span>{{ $t("schools.students_not_has_number_count") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  :color="item.comments && item.comments.length > 0 ? 'warning' : 'dark'"
                  @click="viewItemNote(item)"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-alert-decagram
              </v-icon>
            </template>
            <span> {{ $t("schools.note") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div id="actions" class="text-center" v-if="!filterObj.deleted">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    icon
                    color="dark"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon v-if="!optionsLoading">
                    mdi-dots-vertical
                  </v-icon>

                </v-btn>
              </template>
              <v-list>
                <menu-item :label="$t('schools.open_control_panel')" color="info"
                           icon="mdi-monitor-dashboard" @click="generateSSO(item)"></menu-item>
                <menu-item v-if="$store.getters.isInRole(103)" :label="$t('showAccounts')" color="info" icon="mdi-eye"
                           @click="showAccounts(item)"></menu-item>
                <menu-item v-if="$store.getters.isInRole(7)" :label="$t('edit')" color="info" icon="mdi-pencil"
                           @click="editItem(item)"></menu-item>
                <menu-item v-if="$store.getters.isInRole(101)" :label="$t('config.edit')" color="primary" icon="mdi-cog"
                           @click="configItem(item)"></menu-item>
                <menu-item v-if="$store.getters.isInRole(101)" :label="$t('schools.creation_log')" color="warning"
                           icon="mdi-post-outline" @click="logItem(item)"></menu-item>
                <menu-item v-if="$store.getters.isInRole(102)" :label="$t('schools.edit_finance')" color="warning"
                           icon="mdi-currency-usd" @click="showFinance(item)"></menu-item>
                <menu-item v-if="$store.getters.isInRole(9)" :label="$t('delete')" color="error" icon="mdi-delete"
                           @click="deleteItem(item)"></menu-item>
              </v-list>
            </v-menu>
          </div>
          <v-row v-if="filterObj.deleted">
            <v-col cols="3">
              <v-tooltip top v-if="$store.getters.isInRole(5)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="restoreItem(item)" color="primary" v-bind="attrs" v-on="on">
                    mdi-restore
                  </v-icon>
                </template>
                <span> {{ $t("restore") }} </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
        <template #footer>
          <v-row>
            <v-col cols="6" md="3" class="pt-8">
              <v-text-field readonly outlined :label="$t('schools.total')" dense :value="totalCount"/>
            </v-col>
            <v-col cols="6" md="3" class="pt-8">
              <v-text-field readonly outlined :label="$t('schools.current_count')" dense :value="schools.length"/>
            </v-col>
            <v-col cols="12" md="6">
              <paginate
                  class="mt-4"
                  :current_page.sync="currentPage"
                  :data="filterObj"
                  :key="true"
              />
            </v-col>

          </v-row>
        </template>
      </v-data-table>

    </v-card>
    <Dialog
        :onClose="close"
        :dialog="dialog"
        :editedItem="editedItem"
        :editedIndex="editedIndex"
        :valid="valid"
        :resetValidation="resetValidation"
        :directorates="directorates"
        @refreshTable="refreshTable"
    />
    <config-dialog
        :onClose="closeConfig"
        :dialog="dialogConfig"
        :school-id.sync="selectedSchoolId"
        @refreshTable="refreshTable"
    />
    <Accounts
        :onClose="closeAccounts"
        :dialog="accountsDialog"
        :schoolId="editedItem.id"
        @closeAccounts="closeAccounts"
    />
    <PrintInfo
        :onClose="closePrint"
        :dialog="printDialog"
        :schools="selectedSchools"
        @closePrint="closePrint"
    />
    <confirm-dialog
        :openDialog="dialogRestore"
        :onClicked="restoreItemConfirm"
        :onClose="closeRestore"
    />
    <log-dialog
        :dialog.sync="dialogLog"
        :creation_log="creation_log"
    />
    <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
    />
    <confirm-dialog
        :openDialog="dialogActivate"
        :onClicked="activate"
        :dialogTitle="$t('activate')"
        :onClose="closeActivate"
    />
    <confirm-dialog
        :openDialog="dialogDeactivate"
        :onClicked="deactivate"
        :dialogTitle="$t('deactivate')"
        :onClose="closeDeactivate"
    />
    <finance-dialog
        :dialog.sync="dialogFinance"
        :refresh-table="refreshTable"
        :edited-item="editedItem"
        :year_id="this.selectedSchoolYear"
    />
    <note-dialog
        :dialog.sync="dialogViewNote"
        :refresh-table="refreshTable"
        :school="editedItem"
    />
    <details-dialog
        :dialog.sync="dialogViewDetails"
        :refresh-table="refreshTable"
        :school="editedItem"
    />
    <change-agency-dialog
        :dialog.sync="dialogChangeAgency"
        :refresh-table="refreshTable"
        :schools="selectedSchools"
        :agencies="agencies"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import menuItem from "../../components/menu/menuItem";
import paginate from "./paginate.vue";
import Dialog from "./Dialog.vue";
import FinanceDialog from "./FinanceDialog.vue";
import LogDialog from "./LogDialog";
import ConfigDialog from "./Config.vue";
import NoteDialog from "./NoteDialog";
import DetailsDialog from "./DetailsDialog";
import Accounts from "./Accounts.vue";
import PrintInfo from "./PrintInfo.vue";
import SchoolFilters from "@/views/Schools/schoolFilters";
import ChangeAgencyDialog from "./ChangeAgencyDialog.vue";

export default {
  components: {
    ChangeAgencyDialog,
    SchoolFilters,
    ConfirmDialog,
    NoteDialog,
    DetailsDialog,
    Dialog,
    paginate,
    Accounts,
    PrintInfo,
    ConfigDialog,
    LogDialog,
    FinanceDialog,
    menuItem
  },
  data() {
    return {
      schools: [],
      totalCount: 0,
      printDialog: false,
      accountsDialog: false,
      dialogViewDetails: false,
      selectedSchoolId: null,
      dialogViewNote: null,
      financeFiltersStudent: {
        expected: [0, 0],
        week_active: [0, 0],
        current: [0, 0],
        subscribed: [0, 0],
        active: [0, 0],
      },
      usageCounts: {
        messages: [0, 0],
        notifications: [0, 0],
        classes: [0, 0],
        homeworks: [0, 0],
        quizzes: [0, 0],
      }, financeFiltersTeacher: {
        current: [0, 0],
      }, financeFiltersDriver: {
        current: [0, 0],
      },
      currentPage: 1,
      filterObj: {
        pageNumber: 1,
        deleted: false,
        lessStudents: false,
        name: null,
        domain_scid: null,
        createdAt: null,
        free: null,
        owner: null,
        active: null,
        year_id: null,
        financial_status_student: null,
        financial_status_driver: null,
        agency_id: null,
        directorate_id: null,
        governorate_id: null,
        notificationUsageState:null,
        min_created_student: null,
        has_min_created_student: null
      },
      filterDialog: false,
      filteredSchools: [],
      selectedSchools: [],
      creation_log: '',
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      dialogLog: false,
      dialogRestore: false,
      dialogActivate: false,
      dialogChangeAgency: false,
      dialogConfig: false,
      dialogDeactivate: false,
      dialogFinance: false,
      editedIndex: -1,
      selectedSchoolYear: null,
      schoolYears: [],
      directorates: [],
      governorates: [],
      agencies: [],
      ownerList: {
        private_schools: this.$t("ownershipTypes.private"),
        public_schools: this.$t("ownershipTypes.public"),
      },
      editedItem: {
        id: null,
        name: null,
        free: null,
        free_cause: null,
        domain: null,
        scid: null,
        directorate_id: null,
        owner: null,
        updated_at: null,
        directorate: null,
        types: [],
        groupschs: [],
        years: [],
        locality: null,
        district: null,
        village: null,
        closest_point: null,
        manager_name: null,
        manager_phone: null,
        show_in_statistics: false,
        test: false,
        student_expected_count: null,
        week_active_students: null,
        paid_drivers_count: null,
        paid_students_count: null,
        students_not_has_number_count: null,
        students_has_number_count: null,
        paid_teachers_count: null,
        server_id: null,
      },
      defaultItem: {
        id: null,
        name: null,
        free: null,
        free_cause: null,
        domain: null,
        scid: null,
        directorate_id: null,
        owner: null,
        updated_at: null,
        directorate: null,
        types: [],
        groupschs: [],
        years: [],
        locality: null,
        district: null,
        village: null,
        closest_point: null,
        manager_name: null,
        manager_phone: null,
        show_in_statistics: false,
        test: false,
        student_expected_count: null,
        week_active_students: null,
        paid_drivers_count: null,
        paid_students_count: null,
        paid_teachers_count: null,
        students_not_has_number_count: null,
        students_has_number_count: null,
        server_id: null,
      },
      resetValidation: 0,
      optionsLoading: false
    };
  },
  computed: {
    financeFiltersStudentActive() {
      return Object.keys(this.financeFiltersStudent).filter(key => {
        if (this.financeFiltersStudent[key][0] !== 0 || this.financeFiltersStudent[key][1] !== 0)
          return true;
      });
    }, financeFiltersTeacherActive() {
      return Object.keys(this.financeFiltersTeacher).filter(key => {
        if (this.financeFiltersTeacher[key][0] !== 0 || this.financeFiltersTeacher[key][1] !== 0)
          return true;
      });
    }, financeFiltersDriverActive() {
      return Object.keys(this.financeFiltersDriver).filter(key => {
        if (this.financeFiltersDriver[key][0] !== 0 || this.financeFiltersDriver[key][1] !== 0)
          return true;
      });
    }, activeUsageFilters() {
      return Object.keys(this.usageCounts).filter(key => {
        if (this.usageCounts[key][0] !== 0 || this.usageCounts[key][1] !== 0)
          return true;
      });
    },
    headers() {
      var list = [
        {text: this.$t("schools.schoolName"), value: "name"},
        {
          text: this.$t("schools.directorate"),
          value: "directorate",
        },
        {text: this.$t("schools.domain"), value: "domain"},
        {text: this.$t("schools.created_at"), value: "created_at"},
        {text: this.$t("schools.student_count"), value: "student_count"},
      ];
      // if (this.$store.getters.isInRole(101)) {
      //   list.splice(1, 0, {text: this.$t("schools.print_status"), value: "print_status"})
      // }
      if (this.$store.getters.isInRole(102)) {
        list.splice(5, 0, {text: this.$t("schools.financial_accounted"), value: "financial_accounted"})
      }
      if (this.$store.getters.isInRole(102)) {
        list.splice(7, 0, {text: this.$t("schools.driver_teacher_count"), value: "driver_teacher_count"})
      }
      if (this.$store.getters.isInRole(120)) {
        list.splice(7, 0, {text: this.$t("schools.agency"), value: "agency.title"})
      }
      // if (this.$store.getters.isInRole(102)) {
      //   list.splice(5, 0, {text: this.$t("schools.phone"), value: "phone"})
      // }
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };
      if (this.$store.getters.isInRole(7)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(9)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      actions.templates.push({
        type: "btn",
        icon: "mdi-delete",
        text: this.$t("config"),
        click: this.configItem,
      });
      list.push({text: this.$t("schools.note"), value: "note"});
      list.push(actions);
      return list;
    },
  },

  created() {
    if (!this.$store.getters.isInRole(5)) {
      this.$router.push({name: "notauthorize"});
    }
    this.loading = true;
    this.getSchoolYears();
    this.getDirectorates();
    this.GetGovernorates();
    this.GetAgencies();
  },
  watch: {
    currentPage() {
      this.refreshTable();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogActivate(val) {
      val || this.closeActivate();
    },
    dialogDeactivate(val) {
      val || this.closeDeactivate();
    },
    schools() {
      if (this.schools != null && this.schools.length != 0) {
        this.checkActiveSchools();
      }
    },
    selectedSchoolYear() {
      if (this.selectedSchoolYear != null) {
        this.filterObj.year_id = this.selectedSchoolYear.id;
      }
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.schools.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    viewItem(item) {
      this.editedIndex = this.schools.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogViewDetails = true;
    },
    configItem(item) {
      this.selectedSchoolId = item.id;
      this.dialogConfig = true;
    },
    logItem(item) {
      this.creation_log = item.creation_log ?? "";
      this.dialogLog = true;
    },
    async generateSSO(item) {
      this.optionsLoading = true
      try {
        const response = await this.$axios.post("generateSSO/" + item.id, {noToast: true})
        window.open(response.data, '_blank');
      } finally {
        this.optionsLoading = false
      }
    },
    showAccounts(item) {
      this.editedIndex = this.schools.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.accountsDialog = true;
    },
    showFinance(item) {
      if (item.activation_status == null) {
        this.$toast.error("هذه المدرسة غير مفعله لهذه السنة");
        return;
      }
      this.editedIndex = this.schools.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogFinance = true;
    },
    deleteItem(item) {
      this.editedIndex = this.schools.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    restoreItem(item) {
      this.editedIndex = this.schools.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    viewItemNote(item) {
      this.editedItem = item;
      this.dialogViewNote = true;
    },
    deleteItemConfirm() {
      var deleteItem = this.schools[this.editedIndex];
      this.$axios
          .delete("DeleteSchool/" + deleteItem.id)
          .then(() => {
            this.refreshTable();
          })

      this.closeDelete();
    },
    restoreItemConfirm() {
      var item = this.schools[this.editedIndex];
      this.$axios
          .delete("RestoreSchool/" + item.id)
          .then(() => {
            this.refreshTable();
          })

      this.closeRestore();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },
    closeConfig() {
      this.dialogConfig = false;
      this.selectedSchoolId = null;
    },
    closeAccounts() {
      this.accountsDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closePrint() {
      this.printDialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeRestore() {
      this.dialogRestore = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeActivate() {
      this.dialogActivate = false;
    },
    closeDeactivate() {
      this.dialogDeactivate = false;
    },
    refreshTable() {
      this.selectedSchools = [];
      this.loading = true;
      this.filterObj.year_id = this.selectedSchoolYear;
      let financeFilter = {
        student: {},
        teacher: {},
        driver: {},
        usage: {},
      };
      for (const filter of this.financeFiltersStudentActive) {
        financeFilter.student[filter] = this.financeFiltersStudent[filter].join(',')
      }
      for (const filter of this.financeFiltersTeacherActive) {
        financeFilter.teacher[filter] = this.financeFiltersTeacher[filter].join(',')
      }
      for (const filter of this.financeFiltersDriverActive) {
        financeFilter.driver[filter] = this.financeFiltersDriver[filter].join(',')
      }
      for (const filter of this.activeUsageFilters) {
        financeFilter.usage[filter] = this.usageCounts[filter].join(',')
      }

      this.$store.dispatch("getSchools", {
        ...this.filterObj,
        financeFilter,
        pageNumber: this.currentPage,
      }).then((schools) => {
        this.totalCount = schools.total ?? 0;
        this.schools = schools.data;
        this.loading = false;
        this.checkActiveSchools();
      }).finally(() => {
        this.loading = false;
      });
    },
    exportExcel() {
      this.loading = true;
      this.filterObj.year_id = this.selectedSchoolYear;
      let financeFilter = {
        student: {},
        teacher: {},
        driver: {},
        usage: {},
      };
      for (const filter of this.financeFiltersStudentActive) {
        financeFilter.student[filter] = this.financeFiltersStudent[filter].join(',')
      }

      for (const filter of this.financeFiltersTeacherActive) {
        financeFilter.teacher[filter] = this.financeFiltersTeacher[filter].join(',')
      }
      for (const filter of this.financeFiltersDriverActive) {
        financeFilter.driver[filter] = this.financeFiltersDriver[filter].join(',')
      }
      for (const filter of this.activeUsageFilters) {
        financeFilter.usage[filter] = this.usageCounts[filter].join(',')
      }

      this.$store.dispatch("exportSchools", {
        ...this.filterObj,
        financeFilter,
        pageNumber: this.currentPage,
      }).then(() => {
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      });
    },
    activate() {
      var activeStatus = 1;
      var schools_ids = this.selectedSchools.map((item) => {
        return item.id;
      });

      if (schools_ids.length == 0) {
        this.$toast.warning(this.$t("error.pleaseSelectSchools"));
        this.dialogActivate = false;
        return;
      }

      if (this.selectedSchoolYear == null) {
        this.$toast.warning(this.$t("error.pleaseSelectSchoolYear"));
        this.dialogActivate = false;
        return;
      }

      var obj = {
        year_id: this.selectedSchoolYear,
        schools_ids: schools_ids,
        active: activeStatus,
      };

      this.$axios
          .post("ActiveSchools", obj)
          .then(() => {
          })
          .finally(() => {
            this.dialogActivate = false;
            this.refreshTable();
            this.selectedSchools = [];
          });
    },
    deactivate() {
      var activeStatus = 0;
      var schools_ids = this.selectedSchools.map((item) => {
        return item.id;
      });

      if (schools_ids.length == 0) {
        this.$toast.warning(this.$t("error.pleaseSelectSchools"));
        this.dialogDeactivate = false;
        return;
      }

      if (this.selectedSchoolYear == null) {
        this.$toast.warning(this.$t("error.pleaseSelectSchoolYear"));
        this.dialogDeactivate = false;
        return;
      }

      var obj = {
        year_id: this.selectedSchoolYear,
        schools_ids: schools_ids,
        active: activeStatus,
      };

      this.$axios
          .post("ActiveSchools", obj)
          .then(() => {
          })
          .finally(() => {
            this.dialogDeactivate = false;
            this.refreshTable();
            this.selectedSchools = [];
          });
    },
    rowColor(item) {
      let classes = [];
      if (item.activation_status === 0) {
        classes.push(this.$vuetify.theme.dark ? "darenRed" : "red lighten-5");
      } else if (item.activation_status !== 1) {
        classes.push(this.$vuetify.theme.dark ? "darkOrange" : "orange lighten-5");
      }
      if (item.name.length > 30) {
        // classes.push('height-100');
      }
      return classes.join(' ');
    },
    checkActiveSchools() {
      if (this.schools != null && this.schools.length) {
        var obj = this;
        this.schools.forEach(function (element) {
          if (element.activation_status === 1) {
            element.activeStatus = obj.$t("activationStatus.active");
          } else if (element.activation_status === 0) {
            element.activeStatus = obj.$t("activationStatus.disabled");
          } else {
            element.activeStatus = obj.$t("activationStatus.inactive");
          }
        });
      }
    },
    getSchoolYears() {
      this.$store
          .dispatch("GetYears")
          .then((data) => {
            this.schoolYears = data;
            if (this.schoolYears.length > 0)
              this.selectedSchoolYear = this.schoolYears[0].id;

            this.refreshTable();
          })
          .finally(() => {
            // this.loading = false;
          });
    },
    markPrint(schools) {
      const ids = schools.map(school => school.id);
      for (const school of schools) {
        school.print_status++;
      }
      this.$axios
          .post("UpdateSchool/print", {
            schools: ids,
          })
          .then(() => {
            // this.refreshTable();
          })
          .finally(() => {
            // this.loading = false;
          });
    },
    getDirectorates() {
      this.$store
          .dispatch("GetUserDirectorates")
          .then((data) => {
            this.directorates = data;
            // this.directorates.push({
            //   id: null,
            //   name: this.$t("all"),
            // });
          })
          .finally(() => {
            // this.loading = false;
          });
    },
    GetGovernorates() {
      this.$store
          .dispatch("GetUserGovernorates")
          .then((data) => {
            this.governorates = data;
          })
          .finally(() => {
            //  this.loading = false;
          });
    },
    GetAgencies() {
      if (this.$store.getters.isInRole(120)) {
        this.$axios
            .get("agencies", {params: {noToast: true, all: true}})
            .then((response) => {
              this.agencies = response.data.data;
              this.agencies.unshift({
                id: 0,
                title: "بدون وكالة"
              }, {
                id: -1,
                title: "له وكالة"
              })
            })
            .finally(() => {
              //this.loading = false;
            });
      }
    },
    print() {
      if (this.selectedSchools.length === 0) {
        this.$toast.warning(this.$t("error.pleaseSelectSchools"));
        this.dialogActivate = false;
        return;
      }
      this.markPrint(this.selectedSchools);

      this.$forceUpdate();
      this.printDialog = true;
      this.$forceUpdate();
    },
    toggleDeleted() {
      this.schools.splice(0, this.schools.length);
      this.selectedSchools.splice(0, this.selectedSchools.length);
      this.filterObj.deleted = !this.filterObj.deleted;
      this.refreshTable();
    },
    toggleLessStudents() {
      this.schools.splice(0, this.schools.length);
      this.selectedSchools.splice(0, this.selectedSchools.length);
      this.filterObj.lessStudents = !this.filterObj.lessStudents;
      this.refreshTable();
    },
  },
};
</script>
<style>
.darenRed {
  background-color: #543a3a;
}

.darkOrange {
  background-color: #544432;
}

.height-100 {
  height: 100px !important;
}
</style>
